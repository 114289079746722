import { IFetchRequest, IKycStatus, IMe, IMeKYC, IKYCResponseData } from '../../interfaces';
import { common } from '../index';

// TODO: call api to get the current user
export const getMeUser = ({ fetcher, options }: IFetchRequest): Promise<IMe> => {
  return fetcher.get(`${common.endpoints.path.ME_ENDPOINT}`, options);
};

export const getCheckUserKYCStatus = ({ fetcher, options }: IFetchRequest): Promise<IMe> => {
  return fetcher.get(`${common.endpoints.path.ME_ENDPOINT}/kyc-check`, options);
};

export const updateMeUser = (user: IMe, { fetcher, options }: IFetchRequest): Promise<IMe> => {
  return fetcher.patch(`${common.endpoints.path.ME_ENDPOINT}`, { body: JSON.stringify(user), ...options });
};

export const meKYC = (data: IMeKYC, { fetcher }: IFetchRequest): Promise<IKYCResponseData> => {
  return fetcher.post(`${common.endpoints.path.ME_ENDPOINT}/kyc`, { body: JSON.stringify(data) });
};

export const meKYCStatus = ({ fetcher }: IFetchRequest): Promise<IKycStatus> => {
  return fetcher.get(`${common.endpoints.path.ME_ENDPOINT}/kyc`);
};
