export default {
  na: 'N/A',
  goMain: 'Go to main page',
  close: 'Close',
  back: 'Back',
  next: 'Next',
  save: 'Save',
  cancel: 'Cancel',
  confirm: 'Confirm',
  loading: 'Loading...',
  submit: 'Submit',
  acceptOffer: 'Accept Offer',
  inquire: 'Inquire',
  applicationSubmited: 'Application Submitted',
  submitApplication: 'Submit Application',
  iDontWantTo: 'Skip',
  continue: 'Continue',
  information: {
    faq: 'FAQ',
    contact: 'Contact',
    aboutUs: 'About Us',
  },
  customers: {
    create: 'Create Customer',
    customer: 'Customer',
  },
  time: {
    months: 'months',
  },
  alt: {
    logo: 'Logo',
  },
  sorting: {
    asc: 'Ascending',
    desc: 'Descending',
  },
  menuItems: {
    home: 'Home',
    users: 'Users',
    loanApplications: 'Loan Applications',
    activeLoans: 'Active Financing',
    settings: 'Settings',
    roles: 'Roles',
    account: 'Account',
    login: 'Sign In',
    signUp: 'Sign Up',
    homePage: 'Home',
    loanBook: 'Loan Book',
    repaymentSchedule: 'Repayment Schedule',
    clientProfiles: 'Client Profiles',
    loanProducts: 'Loan Products',
  },
  errors: {
    oops: 'Oops!',
    unexpected: 'Sorry, an unexpected error has occurred. Probably this page is not implemented yet.',
    mustBeNumber: 'Must be a number',
    invalidPhone: 'Invalid phone number',
    invalidIban: 'Invalid Iban',
    verificationError: 'Verification has failed',
    signUpError: 'Sign Up has failed',
    oopsSomethingWentWrong: 'Oops! Something went wrong',
    invalidCrn: 'Invalid CRN: must be 10 digits',
    mobileVerificationError: 'mobile Verification Error',
    required: (fieldName: string) => `${fieldName} is required`,
    unableToOpenTerms: 'Unable to open Terms and Conditions. Please try again.',
    pleaseOpenTermsFirst: 'Please open and read the Terms and Conditions first.',
    missingFields: 'Please fill the missing fields',
    phoneNotOwnedByNid: 'Phone number is not owned by national/iqama id',
  },
  calculator: {
    calculatorDescription:
      '>Please choose the desired financing amount and terms. The platform will display the corresponding calculations for your review.',
    amountSolicited: 'Amount Solicited:',
    term: 'Term:',
    months: 'Months',
    currency: 'SAR',
    monthlyPayments: 'Monthly Payments:',
    profitFess: 'Profit Fees:',
    adminFees: 'Admin Fees:',
    totalLoanRepayment: 'Total Financing Repayment:',
    calculatorAclaration:
      '*Financing fees are determined based on the best Risk Category. The final offer may vary depending on your Credit Score.',
    button: 'Confirm Financing Amount',
    apr: 'Cost of financing: ',
    riskCategory: 'Risk Category:',
  },
  termsAndConditions: {
    loading: 'Loading Terms and Conditions...',
    retry: 'Retry',
    noData: 'No data available',
    switchLanguage: 'Switch to Arabic',
    viewTerms: 'View Terms and Conditions',
    previous: 'Previous',
    next: 'Next',
    zoomOut: 'Zoom Out',
    zoomIn: 'Zoom In',
    downloadPDF: 'Download PDF',
    page: 'Page',
    of: 'of',
    loadingPDF: 'Loading PDF...',
    errorLoading: 'Failed to load PDF. Please try again later.',
    close: 'Close',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    popupBlocked: 'Unable to open print dialog. Please check your popup settings.',
  },
  loanOverview: {
    info: 'Please review the information provided in the previous tabs carefully. Once submitted, changes or updates to this information will not be permitted. This data will be used to conduct an eligibility check.',
    pesonalInfo: 'Pesonal Information',
    name: 'Name:',
    profession: 'Profesion:',
    educationLevel: 'Education Level:',
    documentsAttached: 'Documents Attached:',
    businessInfo: 'Business Information',
    BusinessName: 'Business Name:',
    dateFounded: 'Date Founded:',
    industry: 'Industry:',
    location: 'Location',
    yearRevenue: 'Yearly Revenue:',
    anualSalesGrowth: 'Annual Sales Growth:',
    reasonForTheLoan: 'Reason for the Financing:',
    loanAmountRequest: 'Financing Request',
    employeeCount: 'Employee Count:',
    amount: 'Amount:',
    term: 'Term:',
    dataPP: 'I have read and understood the',
    simah:
      'I authorize Nayla Finance to access my personal credit report and my establishment credit report from Simah',
    iHaveReadTerms: 'I have read and understood the',
    openTermsAndConditions: 'Terms and Conditions',
    openDataPrivacyPolicy: 'Data Privacy Policy',
    acceptIt: ' .',
  },
  authentication: {
    verifyMobile: 'Verify Mobile Number',
    verifyMFA: 'Verify MFA',
    codeLabel: 'OTP Code',
    resendCode: 'Resend Code',
    termsAndConditions: 'I have read and agree to the Privacy Policy and Terms of Services',
    dataAgreement: 'I acknowledge and authorize to collect my data from third parties',
    login: 'Sign In',
    signUp: 'Sign Up',
    verifyEmail: 'Verify Email',
    code: 'Enter the code',
    or: 'OR',
    forgotPassword: 'Forgot Password',
    forgotPasswordLabel: 'Forgot your password?',
    changePassword: 'Change Password',
    verifyCode: 'Verify Code',
    signOut: 'Sign Out',
    googleSignIn: 'Login with Google',
    googleSignUp: 'Sign Up with Google',
    passwordHint: 'The password should have 8 characters, lower and upper case, numbers and special characters.',
    repeatPassword: 'Repeat Password',
    newPassword: 'New Password',
    passwordsShouldNotMatch: 'New password should not match the old password',
    passwordsDoNotMatch: 'Passwords do not match',
    getCode: 'Get code!',
    emailVerified: 'Email verified!',
    codeSent: 'Code sent!',
    passwordChanged: 'Password changed!',
    previousPasswordInvalid: 'Please enter a valid previous password',
    validationMessage:
      'Password must contain at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character',
    temporaryPasswordMustBeUsed: 'A temporary password must be used to trigger a mandatory change password request',
  },
  documentsUpload: {
    upload: 'Upload Documents',
    guidelines: 'Kindly submit the following documents for financing application processing:',
    bank_statement: 'Three months of bank statements',
    proof_of_debt: 'Documentation confirming your outstanding debt obligations',
    files: 'Files',
  },
  businesses: {
    entityName: 'Business',
    properties: {
      name: 'Company Name',
      dateFounded: 'Date Founded',
      industry: 'Industry',
      location: 'Location / City',
      anualSales: 'Annual Sales Growth (%)',
      averageYearly: 'Average Yearly Revenue (SAR)',
      yearlyRevenue: 'Yearly Revenue (SAR)',
      description: 'Explain the specific reason and intended use for this financing amount.’',
      loanPurpose: 'Loan Purpose',
      employeeCount: 'Employee Count',
    },
  },
  disbursement: {
    description:
      'To finalize the disbursement, kindly provide the corporate bank account information of your organization.',
    description2:
      'Please note that if the bank account ownership does not match the company name as per the registered CR, the disbursement of financing cannot be completed.',
    iban: 'IBAN',
    bank: 'Bank',
    success: 'Thank you for submitting your bank account details. You will receive your disbursement soon!',
  },
  users: {
    entityName: 'User',
    loading: 'Loading users...',
    tableTitle: 'Users',
    tableDescription: 'Manage your team and preferences here.',
    createUserTitle: 'Create User',
    editUserTitle: 'Edit User',
    deleteUserTitle: 'Delete User',
    deleteUserDescription: 'Are you sure you want to delete this user?',
    changePassword: 'Change Password?',
    changeLanguage: 'EN | AR',
    typePassword: 'Type your password here',
    rememberMe: 'Remember me',
    properties: {
      isPep:
        'Are you a Politically Exposed Person? Or do you have a first-degree or second-degree relationship with any Politically Exposed Person?',
      isPepYes: 'Yes, some of these apply',
      isPepNo: 'No, none of these apply',
      username: 'Username',
      email: 'Email',
      enabled: 'Status',
      roles: 'Roles',
      phone: 'Phone',
      firstName: 'First Name',
      lastName: 'Last Name',
      password: 'Password',
      code: 'Code',
      profession: 'Profession',
      education: 'Education Level',
    },
    statuses: {
      FORCE_CHANGE_PASSWORD: 'Force Change Password',
      CONFIRMED: 'Confirmed',
      UNCONFIRMED: 'Unconfirmed',
      ARCHIVED: 'Archived',
      COMPROMISED: 'Compromised',
      UNKNOWN: 'Unknown',
      RESET_REQUIRED: 'Reset Required',
    },
    actions: {
      add: 'Add User',
      edit: 'Edit User',
      delete: 'Delete User',
    },
    enabled: {
      true: 'Active',
      false: 'Inactive',
    },
  },
  activeLoans: {
    overview: 'Overview',
    description: 'Financing Description',
    repaymentSchedule: 'Repayment Schedule',
    riskAssestmentResults: 'Risk assessment Results:',
    riskCategoryScore: 'Risk Category Score:',
    downloadSignedContract: 'Download Signed Contract',
    loanOverview: 'Financing Overview',
    loanAmount: 'Financing Amount',
    financingTerms: 'Financing Term',
    monthlyInstalments: 'Monthly instalments',
    totalCotsFinancing: 'Total cost financing',
    totalRepayableAmount: 'Total Repayable amount',
    title: 'Active Financing',
    loanId: 'Financing ID: ',
    status: 'Status',
    applicationId: 'Application ID',
    nextPayment: 'Next Payment SAR',
    nextPaymentDate: 'Next Payment Date',
    remainingInstalments: 'Remaining Instalments',
    remainingBalance: 'Remaining Balance SAR',
    startDate: 'Start Date',
    loanTerm: 'Financing Term',
    details: 'See details',
    noActiveLoans: 'No active financing',
  },
  roles: {
    entityName: 'Role',
    loading: 'Loading roles...',
    tableTitle: 'Roles',
    tableDescription: "Manage your team's roles and preferences here.",
    createRoleTitle: 'Create Role',
    editRoleTitle: 'Edit Role',
    deleteRoleTitle: 'Delete Role',
    deleteRoleDescription: 'Are you sure you want to delete this role?',
    properties: {
      rolename: 'Role Name',
      description: 'Description',
      users: 'Users',
    },
  },
  loanApplications: {
    entityName: 'Financing Application',
    startNewApp: 'Start New Application',
    noBusiness: 'This user has no business',
    newApplicationOn: 'New Application on',
    loading: 'Loading applications...',
    editRoleTitle: 'Edit application',
    deleteRoleTitle: 'Delete application',
    personalInfo: 'Personal Info',
    businessInfo: 'Business Info',
    documents: 'Documents',
    calculator: 'Calculator',
    overview: 'Overview',
    consent: 'Consent',
    payment: 'Payment',
    disbursement: 'Disbursement',
    offer: 'Offer',
    title: 'Financing Applications',
    description: 'Application stages',
    applicationNumber: 'Application No.',
    status: 'Status:',
    submitted: 'Submitted',
    amount: 'Amount Solicited: ',
    currency: 'SAR',
    paymentSchedule: 'Payment Schedule:',
    months: 'Months',
    details: 'See details',
    contract: 'Contract',
    confirmCommodityDeal: 'Commodity Deal',
    offerScreen: {
      offerOverview: 'Offer Overview',
      hi: 'Hi there.',
      preparingText: 'Congratulations. We are preparing an offer for you. ',
      offerText: 'You will be notified as soon as possible! ',
      loanAmountRequest: 'Financing Amount Request',
      amount: 'Amount: ',
      term: 'Term: ',
      riskAssestmentResults: 'Risk Assesment Results',
      riskCategoryScore: 'Risk Category Score',
      questions: 'For any questions please contact us:',
      contact: 'For any questions please contact us: customer@naylafinance.com',
      yourOfferIsThere: "Your offer is ready for your review. Here's what to do next:",
      point1:
        "To accept the offer, click 'Accept Offer'. We'll prepare formal e-contracts and promissory notes, then notify you when they are ready for your digital signature",
      point2:
        'For any changes to the offer (amount or financing terms), please contact us at customersupport@naylafinance.com.',
      point3: 'If you do not want to take the offer, or inquire it, please click "Reject offer".',
      point4: 'Please note that if no action is taken within the next 7 working days, the offer will expire.',
      loanAmount: 'Financing Amount',
      financingTerms: 'Financing Term',
      monthlyInstalments: 'Monthly instalments',
      totalCotsFinancing: 'Total cost financing',
      totalRepayableAmount: 'Total Repayable amount',
    },
  },
  settings: {
    title: 'Settings',
  },
  payment: {
    text1: 'To proceed with your application, kindly provide the details of your Debit/Credit Corporate Card',
    text2:
      'As part of our secure verification process, a nominal fee of 0.09 SAR will be debited to confirm the validity of the card.',
    text3: 'Monthly repayments will be automatically and securely deducted from this card',
    text4: ' ',
    iConsent: 'I agree to the terms and conditions outlined above',
    success:
      'Thank you for submitting your payment information. Please visit the Disbursement page and enter your bank account information to receive your financing.',
  },
  loanConsent: {
    hiTitle: 'Hi there',
    howTitle: 'How It Works',
    openBankTitle: 'Open Banking',
    hiText1:
      'At Nayla, we offer a transparent and secure process for accessing your financial data to enhance your financing application experience.',
    hiText2:
      'Our open banking consent allows us to retrieve specific financial information, aiding in a quick and tailored credit assessment. ',
    hiText3:
      'Rest assured, this process is entirely voluntary, giving you control over your data. We also handle your data with data privacy and maintain a secure connection throughout.',
    hiText4: 'This process is approved and regulated by SAMA (Saudi Monetary Authority).',
    howText: "To begin connecting your bank account, simply click 'Connect Open Banking.'",
    howText2:
      "If you don't have your online banking credentials on hand, you can return to complete this step within the next 24 hours.",
    howCheckbox: 'I consent to the use of my financial data through open banking by Nayla Finance',
    openBankText: 'Please start the process of connecting your bank account, clicking connect. ',
    connect: 'Connect',
    connected: 'Connected',
  },
  products: {
    entityName: 'Product',
    properties: {
      name: 'Name',
      description: 'Description',
      priceCents: 'Price',
      quantity: 'Quantity',
    },
    tableTitle: 'Products',
    loading: 'Loading products...',
    editProductTitle: 'Edit product',
    createProductTitle: 'Create product',
  },
  placeholders: {
    email: 'max-mustermann@gmail.com',
    username: 'max-mustermann',
    name: 'name',
    firstName: 'Max',
    lastName: 'Mustermann',
    profession: 'Developer',
    education: 'High School Diploma',
    businessName: 'Jeddah Travel Company',
    dateFounded: '12.09.2022',
    industry: 'Travel',
    location: 'Jeddah',
    averageYearly: '4000,000 SAR',
    yearlyRevenue: 'Less than or equal to 3 millions',
    anualSales: '5%',
    description: 'description',
    phone: '+49 123 456789',
    password: 'Password',
    code: '123456',
    role: 'admin',
    roleDescription: 'Role that has access to everything',
    select: (objectName: string) => `Select ${objectName}`,
    price: '0',
    quantity: '0',
    crn: '1234567890',
    nid: '1234567890',
    purpose: 'Growth',
    employeeCount: '5 employees Or less',
  },
  bankInfo: {
    title: 'IBAN Information',
    bank: 'Bank: ',
    iban: 'IBAN:',
    bic: 'BIC',
    lastUpdated: 'Last Updated',
    updateInfo: 'Update Info',
  },
  updateBankPopup: {
    title: 'Update your Bank info',
    description: 'Please update you banking information, this is where your disbursements will be deposited.',
    codeDescription:
      'In order to confirm your identity and safety reasons, Please insert the 6 digit code to your email / phone:',
    chooseYourBank: 'Choose your bank',
    accountNumber: 'Account Number',
    swiftCode: 'Swift Code',
    code: 'XXXXXX',
  },
  table: {
    actions: {
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      enable: 'Enable',
      disable: 'Disable',
    },
  },
  paymentSchedule: {
    month: 'Month',
    paymentDate: 'Payment Date',
    beginningBalance: 'Beginning Balance',
    monthlyInstalment: 'Monthly Instalment',
    principal: 'Principal',
    profit: 'Profit',
    endingBalance: 'Ending Balance',
    status: 'Status',
    noInstalments: 'No instalments',
  },
  instalments: {
    tableTitle: 'Repayment Schedule',
    tableDescription: 'Active Loans',
    filters: {
      startDate: 'By Start Date',
      distributionPartner: 'By Distribution Partner',
      productType: 'By Product Type',
      status: 'By Status',
      from: 'From',
      to: 'To',
    },
    properties: {
      transactionId: 'Transaction ID',
      amount: 'Amount',
      loanId: 'Loan ID',
      paymentMode: 'Payment Mode',
      smeCR: 'SME CR',
      dueDate: 'Due Date',
      distributionPartner: 'Distribution Partner',
      loanStatus: 'Loan Status',
    },
    states: {
      DUE: 'Due',
      OVERDUE: 'Overdue',
      DELAYED: 'Delayed',
      UNSETTLED: 'Unsettled',
      SETTLED: 'Settled',
      OVER_SETTLED: 'Over Settled',
      PARTIALLY_SETTLED: 'Partially Settled',
      SETTLED_WITH_DELAY: 'Settled with Delay',
      OVER_SETTLED_WITH_DELAY: 'Over Settled with Delay',
      PARTIALLY_SETTLED_WITH_DELAY: 'Partially Settled with Delay',
    },
  },
  loanState: {
    ACTIVE: 'Active',
    DELAYED: 'Delayed',
    SETTLED: 'Settled',
    ACTIVE_WITH_DELAY: 'Active with delay',
    SETTLED_WITH_DELAY: 'Settled with delay',
    DEFAULTED: 'Defaulted',
    REJECTED: 'Rejected',
    PARTIALLY_COMPLETE: 'Partially Completed',
    SUBMITTED: 'Submitted',
    PRE_APPROVED: 'Pre-approved',
    IDENTITY_VERIFICATION: 'Identity Verification',
    UNDERWRITING: 'UW',
    OFFER: 'Offer',
    APPROVED: 'Approved',
    SIGNED: 'Signed',
    EXPIRED: 'Expired',
    DISBURSE: 'Disbursed',
    DEFERRED: 'Deferred',
    NA: 'N/A',
  },
  toasts: {
    success: {
      created: (objectName: string) => `${objectName} created`,
      updated: (objectName: string) => `${objectName} updated`,
      deleted: (objectName: string) => `${objectName} deleted`,
      enabled: (objectName: string) => `${objectName} enabled`,
      disabled: (objectName: string) => `${objectName} disabled`,
      loanApplicationCreation: 'Application created',
      loanApplicationSubmited: 'Application submitted',
      loanApplicationAccepted: 'Loan application accepted successfully',
      offerAcceptedAmlApprovedContractCreated: 'Offer accepted, AML approved, and contract created successfully',
      offerAcceptedAmlPending: 'Offer accepted, AML approval pending',
      offerAcceptedAmlApprovedContractPending: 'Offer accepted, AML approved, contract creation pending',
      offerAcceptedWithoutAmlAndContractPending: 'Offer accepted, AML still pending, contract creation pending',
      passwordChanged: 'Password changed. You can now login with your new password',
      verified: 'User verified',
      verificationEmailSent: 'A verification email has been sent. Please check your inbox.',
      data_temp_saved: 'Data was temporarily saved',
      otpResent: 'OTP resent. Please check your phone',
    },
    errors: {
      create: (objectName: string) => `Error creating ${objectName}`,
      update: (objectName: string) => `Error updating ${objectName}`,
      delete: (objectName: string) => `Error deleting ${objectName}`,
      enable: (objectName: string) => `Error enabling ${objectName}`,
      disable: (objectName: string) => `Error disabling ${objectName}`,
      fetchError: (objectName: string) => `Error fetching ${objectName}`,
      sessionExpired: 'Session expired, please login again',
      passwordChangedError: 'Error changing password',
      offerAcceptFailed: 'Failed to accept the offer. Please try again.',
    },
  },
  repayment: {
    tableTitle: 'Repayment Schedule',
    tableDescription: 'Active Financing',
    filters: {
      startDate: 'By Start Date',
      distributionPartner: 'By Distribution Partner',
      productType: 'By Product Type',
      status: 'By Status',
      from: 'From',
      to: 'To',
    },
    properties: {
      transactionId: 'Transaction ID',
      amount: 'Amount',
      loanId: 'Loan ID',
      paymentMode: 'Payment Mode',
      smeCR: 'SME CR',
      dueDate: 'Due Date',
      distributionPartner: 'Distribution Partner',
      loanStatus: 'Financing Status',
    },
  },
  loanBook: {
    title: 'Loan Book',
    tableDescription: 'Active financing',
    filters: {
      startDate: 'By Start Date',
      distributionPartner: 'By Distribution Partner',
      productType: 'By Product Type',
      status: 'By Status',
      from: 'From',
      to: 'To',
    },
    properties: {
      smeName: 'SME Name',
      cr: 'CR',
      loanAmount: 'Financing Amount SAR',
      loanType: 'Financing Type',
      loanId: 'Loan ID',
      distributionPartner: 'Distribution Partner',
      startDate: 'Start date',
      loanStatus: 'Loan Status',
      client: 'Client',
      nextPaymentDay: 'Next Payment Day',
      paymentStatus: 'Payment Status',
    },
  },
  loanApplication: {
    title: 'Financing Applications',
    detailTitle: 'Application Details',
    description: 'Application Stages',
    columnStates: {
      SUBMISSIONS: 'Submissions',
      FINAL_RISK_ASSESSMENT: 'Final Risk Assessment',
      CONTRACT_NEGOTIATION: 'Contract Negotiation',
      DISBURSEMENT: 'Disbursement',
    },
    states: {
      partiallyComplete: 'Partially Completed',
      submitted: 'Submitted',
      preApproved: 'Pre-approved',
      identityVerification: 'Identity Verification',
      underwriting: 'Underwriting',
      offer: 'Offer',
      approved: 'Approved',
      rejected: 'Rejected',
      signed: 'Signed',
      expired: 'Expired',
      deferred: 'Deferred',
    },
  },
  applicationStates: {
    underProcess: 'Your application is under process, we will come back to you in 3 business days.',
    invalid:
      'Unfortunately, the offer is no longer valid. Please re-apply or contact us at customersupport@naylafinance.com.',
    paused:
      'Upon your request, the application process is paused. To resume the process in the future, please contact us at customersupport@naylafinance.com.',
    cannotGoForward: 'Unfortunately, we are unable to proceed further with your application.',
    submitted: 'Congratulations, your application has been submitted!',
    approved: 'Congratulations! Your application is approved! We are preparing an offer for you.',
    preApproved: 'Congratulations! Your application has been pre-approved, we will reach out to you in 3 days.',
    received:
      'Congratulations on receiving an offer! Please take a moment to review and accept it, or reach out to our team for further assistance.',
    contract:
      'Congratulations on receiving a contract! Please take a moment to review and accept it, or reach out to our team for further assistance.',
    preparing:
      'We are preparing formal e-contracts and promissory notes for your digital signature. We will notify you once they are ready.',
    disbursement:
      'Congratulations, your financing has been successfully disbursed! You should expect to receive the amount within 2 working days.',
    signed: 'Congratulations! You signed the contract! ',
  },
  inquireOfferModal: {
    title: 'Inquire Offer',
    description: 'For any inquires please contact us at customersupport@naylafinance.com.',
    declineButton: 'Cancel',
    acceptButton: 'Continue',
  },
  acceptOfferModal: {
    title: 'Accept Offer',
    description:
      'After this step, Nayla team will be preparing a contract for you to sign based on this offer that you accepted. This contract will have legal commitments.',
    declineButton: 'Cancel',
    acceptButton: 'Continue',
  },
  loanContract: {
    contractInfo: 'We are preparing a contract for you.',
    contractQuestions: 'customer@naylafinance.com',
    contractText1: "You've received an SMS with a link to sign the e-contracts and promissory notes. ",
    contractText2: 'Please proceed to the contracts.sa portal, and carefully review the documents before signing. ',
    contractText3: 'Remember, your digital signature holds the same legal validity as a handwritten one.',
    contractLink: 'contracts.sa',
    viewContract: 'View Contract',
    downloadError: 'An error occurred while downloading the contract. Please try again.',
    noContractAvailable: 'no Contract Available',
    clickToViewContract: 'Click the button above to view your contract in a new tab',
    contractSecondText: ' portal and go through contract and promissory notes signature.',
    contractSigned1: 'Congratulations on signing the contracts!',
    contractSigned2: 'There are just two more steps remaining before you receive your financing. ',
    contractSigned3:
      'Please complete the necessary information in the Payment & Disbursement tabs to proceed with the disbursement process.',
    loadingContract: 'Loading contract details...',
    loading: 'Loading...',
    loadingPdf: 'Loading PDF...',
    pdfLoadError: 'Error loading PDF. Please try again.',
    invalidPdfData: 'The downloaded contract data is invalid. Please try again or contact support.',
  },
  confirmCommodityDeal: {
    label: 'To continue your loan progress, authorize nayla to sell a commodity on your behalf',
    missingDealKey: 'Please make sure you accepted the offer',
    confirmedDealMessage: 'You have confirmed your deal',
    button: 'Confirm',
  },
  loanProducts: {
    riskCategories: {
      APlus: 'A+',
      A: 'A',
      AMinus: 'A-',
      BPlus: 'B+',
      B: 'B',
      REJECTED: 'Rejected',
    },
    entityName: 'Product',
    properties: {
      name: 'Name',
      description: 'Description',
      priceCents: 'Price',
      quantity: 'Quantity',
    },
    tableTitle: 'Products',
    loading: 'Loading products...',
    editProductTitle: 'Edit product',
    createProductTitle: 'Create product',
  },
  kyb: {
    getVerified: 'Get Verified',
    mustAccept: 'You must accept this to continue',
    properties: {
      crn: 'Company Registration Number',
      nid: 'National ID',
    },
    errors: {
      kybFailed: 'Company is not owned by National ID / Iqama Owner',
    },
  },
  kyc: {
    title: 'Verification',
    confirmNafath:
      'Please go to your Nafath mobile application and choose the below number to complete your authentication request',
    errors: {
      kycFailed: 'User verification failed / expired',
    },
  },
  loanApplicationDocuments: {
    instructions:
      'Please ensure that your financial statements are in PDF or PNG format and do not exceed 30 MB each. You can upload up to 5 files.',
    errors: {
      invalidType: 'Please upload a PDF or PNG file.',
      invalidSize: 'The file size exceeds the maximum allowed limit of 30 MB.',
      maxDocumentCount: 'You have exceeded the maximum allowed number of files (5).',
      failedToUpload: 'Failed to upload document',
      minDocumentsRequired: 'At least one document is required',
    },
  },
};
