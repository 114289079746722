import { FC, useEffect, useMemo, useRef, useState } from 'react';
import useStyles from './styles';
import { PaymentSchedule, toRem } from '@nayla/ui';
import Tag from '@stryberventures/gaia-react.tag';
import { CREDIT_SCORE_OPTIONS } from '@nayla/ui/components/RiskAssessmentDropdown';
import * as yup from 'yup';
import {
  ICalculator,
  IRiskAssessment,
  loanApplicationQueryKeys,
  LoanApplicationState,
  numberWithCommas,
  queryClient,
  RiskAnalystType,
  RiskCategoryEnum,
  useAddOfferMutation,
  useCalculatorLoanApplicationMutation,
  useRiskAssessmentsQuery,
} from '@nayla/common';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import Form from '@stryberventures/gaia-react.form';
import Button from '@stryberventures/gaia-react.button';
import Input from '@stryberventures/gaia-react.input';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import adminVocab from '@/vocabulary';
import { enqueueSnackbar } from 'notistack';
import vocab from '@nayla/vocabulary';
import dayjs from 'dayjs';
import { loanApplicationDetailViewExpandArray } from '@/screens/LoanApplications/detail';

const CalculatorTab: FC = () => {
  const classes = useStyles();
  const formRef = useRef<IFormRef>(null);
  const loanApp = useAtomValue(loanApplication);
  const { data: riskAssessments, isLoading: areRiskAssessmentsLoading } = useRiskAssessmentsQuery(loanApp?.id);
  const sortedRiskAssessments = riskAssessments?.sort(
    (a: IRiskAssessment, b: IRiskAssessment) => dayjs(a?.createdAt).valueOf() - dayjs(b?.createdAt).valueOf(),
  );
  // we are taking the second risk assessment because it is more important, according to the business logic
  const initialRiskAssessment: IRiskAssessment | null = (sortedRiskAssessments && sortedRiskAssessments[1]) ?? null;
  const [calculatorValues, setCalculatorValues] = useState<ICalculator>();
  const minOfferAmount = parseInt(loanApp?.loanProduct?.minLoanAmount || '1');
  const maxOfferAmount = parseInt(initialRiskAssessment?.maximumAmount || '1');
  const [amount, setAmount] = useState<string>();
  const [terms, setTerms] = useState<number | null>(loanApp?.terms ?? null);
  const [adminFees, setAdminFees] = useState<string>(loanApp?.loanProduct.adminFees ?? '3');
  const { mutate: calculate } = useCalculatorLoanApplicationMutation({
    onSettled: () => {},
    onError: (errorMessage) => {
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    onSuccess: (data: ICalculator) => setCalculatorValues(data),
  });
  const { mutate: addOffer } = useAddOfferMutation({
    onSettled: () => {
      queryClient.invalidateQueries(
        loanApplicationQueryKeys.loanApplication(loanApp?.id, loanApplicationDetailViewExpandArray),
      );
      queryClient.invalidateQueries(loanApplicationQueryKeys.riskAssessments(loanApp?.id));
      queryClient.invalidateQueries(loanApplicationQueryKeys.offers(loanApp?.id));
    },
    onSuccess: () => {
      enqueueSnackbar(adminVocab.toasts.success.created(adminVocab.loanApplication.offer.entityName), {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(adminVocab.toasts.errors.create(adminVocab.loanApplication.offer.entityName), {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (!amount && riskAssessments) {
      const riskAssessment = riskAssessments.find(
        (riskAssessment) => riskAssessment.analystType === RiskAnalystType.UNDERWRITER_LEVEL_2,
      );
      if (riskAssessment) {
        setAmount(riskAssessment.maximumAmount);
      }
    }
  }, [amount, riskAssessments]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      amount: yup
        .number()
        .typeError(adminVocab.errors.mustBeNumber)
        .required(adminVocab.loanApplication.properties.amount)
        .min(
          minOfferAmount ?? 0,
          adminVocab.errors.min(
            adminVocab.loanApplication.riskAssessment.properties.creditScore,
            parseInt(minOfferAmount.toString()) ?? 0,
          ),
        )
        .max(
          maxOfferAmount ?? Number.MAX_SAFE_INTEGER,
          adminVocab.errors.max(
            adminVocab.loanApplication.riskAssessment.properties.creditScore,
            parseInt(maxOfferAmount.toString()) ?? Number.MAX_SAFE_INTEGER,
          ),
        ),
      terms: yup.string().required(adminVocab.errors.required(adminVocab.loanApplication.properties.terms)),
      adminFees: yup.string().required(adminVocab.errors.required(adminVocab.loanApplication.properties.adminFees)),
    });
  }, [maxOfferAmount, minOfferAmount]);

  const initialValues = useMemo(
    () => ({
      amount:
        riskAssessments?.find((riskAssessment) => riskAssessment.analystType === RiskAnalystType.UNDERWRITER_LEVEL_2)
          ?.maximumAmount ?? maxOfferAmount,
      terms: loanApp?.terms,
      adminFees: loanApp?.loanProduct?.adminFees,
    }),
    [riskAssessments, loanApp, maxOfferAmount],
  );

  useEffect(() => {
    formRef.current?.submit();
  }, [amount, adminFees, terms, initialRiskAssessment]);

  if (![LoanApplicationState.APPROVED, LoanApplicationState.OFFER].includes(loanApp?.state as LoanApplicationState)) {
    return <div className={classes.noContent}>{adminVocab.loanApplication.notContractState}</div>;
  }

  if (areRiskAssessmentsLoading) {
    return <p>{adminVocab.loading}</p>;
  }

  if (!initialRiskAssessment) {
    return <div className={classes.noContent}>{adminVocab.errors.oopsSomethingWentWrong}</div>;
  }

  const onSendOffer = () => {
    if (loanApp?.id && amount && terms) {
      addOffer({
        loanApplicationId: loanApp?.id.toString(),
        offer: {
          amount,
          terms,
          adminFees,
        },
      });
    }
  };

  return (
    <div className={classes.container}>
      <Form
        onSubmit={(data: any) => {
          const id = loanApp?.id;
          if (id && formRef?.current?.isValid) {
            calculate({
              id,
              calculatorData: {
                amount: data?.amount?.toString(),
                terms: parseInt(data.terms),
                adminFees: data?.adminFees?.toString(),
              },
            });
          }
        }}
        ref={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        className={classes.calculatorContainer}
      >
        <div className={classes.calculatorColumn}>
          {initialRiskAssessment ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: toRem(10) }}>
              <p style={{ fontWeight: 600, flex: '1 1 100%' }}>Risk Category:</p>
              <Tag
                className={classes.tagCentered}
                color={
                  CREDIT_SCORE_OPTIONS.find((score) => score.value === initialRiskAssessment?.riskCategory?.name)?.color
                }
              >
                {initialRiskAssessment
                  ? vocab().loanProducts.riskCategories[initialRiskAssessment.riskCategory?.name as RiskCategoryEnum]
                  : adminVocab.na}
              </Tag>
            </div>
          ) : null}
          <div className={classes.divider}></div>
          <p>
            {adminVocab.loanApplication.offer.properties.aprRate}:{' '}
            {initialRiskAssessment?.riskCategory?.value
              ? `${12 * parseInt(initialRiskAssessment.riskCategory.value)}%`
              : adminVocab.na}
          </p>
          <p>
            {adminVocab.loanApplication.offer.properties.monthlyRate}:{' '}
            {parseInt(initialRiskAssessment.riskCategory.value).toFixed(2)}%
          </p>
          <div className={classes.divider}></div>
          <Input
            onBlur={(e) => setAdminFees(e.target.value)}
            type="text"
            name="adminFees"
            label={adminVocab.loanApplication.offer.properties.adminFees}
          />
          <div className={classes.calculatorRow}>
            <Input
              onBlur={(e) => setAmount(e.target.value)}
              type="text"
              name="amount"
              label={adminVocab.loanApplication.offer.properties.amount}
            />
            <Input
              onBlur={(e) => setTerms(parseInt(e.target.value))}
              type="number"
              name="terms"
              label={adminVocab.loanApplication.offer.properties.terms}
            />
          </div>
        </div>
        <div className={classes.calculatorColumn}>
          <div className={classes.outcomeTitle}>Offer:</div>
          <div>
            <p>
              {adminVocab.loanApplication.calculator.totalAdminFees}: SAR{' '}
              {numberWithCommas(calculatorValues?.summary?.totalAdminFees ?? 0)}
            </p>
            <div className={classes.divider}></div>
          </div>
          <div>
            <p>
              {adminVocab.loanApplication.calculator.totalAmountDisbursed}: SAR{' '}
              {numberWithCommas(calculatorValues?.summary?.totalAmountDisbursed ?? 0)}
            </p>
            <div className={classes.divider}></div>
          </div>
          <div>
            <p>
              {adminVocab.loanApplication.calculator.monthlyInstalment}: SAR{' '}
              {numberWithCommas(calculatorValues?.summary?.monthlyInstalment ?? 0)}
            </p>
            <div className={classes.divider}></div>
          </div>
          <div>
            <p>
              {adminVocab.loanApplication.calculator.numberOfInstalments}:{' '}
              {numberWithCommas(calculatorValues?.summary?.numberOfInstalments ?? 0)}
            </p>
            <div className={classes.divider}></div>
          </div>
          <div>
            <p>
              {adminVocab.loanApplication.calculator.totalPrinciplePlusProfit}: SAR{' '}
              {numberWithCommas(calculatorValues?.summary?.totalPrinciplePlusProfit ?? 0)}
            </p>
            <div className={classes.divider}></div>
          </div>
          <div>
            <p>
              {adminVocab.loanApplication.calculator.totalProfitFees}: SAR{' '}
              {numberWithCommas(calculatorValues?.summary?.totalProfitFees ?? 0)}
            </p>
            <div className={classes.divider}></div>
          </div>
          <div>
            <p>
              {adminVocab.loanApplication.calculator.costOfFinancing}: SAR{' '}
              {numberWithCommas(calculatorValues?.summary?.costOfFinancing ?? 0)}
            </p>
            <div className={classes.divider}></div>
          </div>
          <div>
            <p>
              {adminVocab.loanApplication.calculator.totalRepayableAmount}: SAR{' '}
              {numberWithCommas(calculatorValues?.summary?.totalRepayableAmount ?? 0)}
            </p>
            <div className={classes.divider}></div>
          </div>
          <p style={{ fontWeight: 600 }}>{adminVocab.loanApplication.offer.scrollDown}</p>
        </div>
        <div className={classes.calculatorColumn}>
          <Button
            disabled={!formRef?.current?.isValid}
            onClick={onSendOffer}
            type="button"
            variant="outlined"
            className={classes.sendOfferBtn}
          >
            {adminVocab.loanApplication.offer.sendOffer}
          </Button>
        </div>
      </Form>
      <div className={classes.offerTable}>
        <PaymentSchedule statusShown={false} amortizations={calculatorValues?.theoreticalAmortizationTable ?? []} />
      </div>
    </div>
  );
};

export default CalculatorTab;
