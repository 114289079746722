import useStyles from './styles';
import Form from '@stryberventures/gaia-react.form';
import adminVocab from '@/vocabulary';
import Tag from '@stryberventures/gaia-react.tag';
import {
  getTagColorByLoanApplicationState,
  loanApplicationQueryKeys,
  LoanApplicationState,
  queryClient,
  RiskAnalystType,
  RiskCategoryEnum,
  useAddRiskAssessmentMutation,
  useLoanProductQuery,
} from '@nayla/common';
import vocab from '@nayla/vocabulary';
import Input from '@stryberventures/gaia-react.input';
import { IRiskAssessmentOption, RiskAssessmentDropdown } from '@nayla/ui/components/RiskAssessmentDropdown';
import TextArea from '@stryberventures/gaia-react.text-area';
import Button from '@stryberventures/gaia-react.button';
import { enqueueSnackbar } from 'notistack';
import { ModalDialog } from '@/components/Dialog';
import { useMemo, useRef, useState } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import * as yup from 'yup';
import { loanApplicationDetailViewExpandArray } from '@/screens/LoanApplications/detail';

interface RiskAnalysisFormProps {
  preliminaryAssessmentResult: LoanApplicationState | 'NA';
  riskAnalystType: RiskAnalystType;
}

const RiskAnalysisForm = ({ preliminaryAssessmentResult, riskAnalystType }: RiskAnalysisFormProps) => {
  const classes = useStyles();
  const formRef = useRef<IFormRef>(null);
  const data = useAtomValue(loanApplication);
  const [confirmModalShown, setConfirmModalShown] = useState(false);
  const [riskCategoryValue, setRiskCategoryValue] = useState<RiskCategoryEnum | null>(null);
  const { data: loanProduct } = useLoanProductQuery(data?.loanProduct?.id, ['riskCategories']);
  const { mutate: addRiskAssessment } = useAddRiskAssessmentMutation({
    onSettled: () => {
      queryClient.invalidateQueries(
        loanApplicationQueryKeys.loanApplication(data?.id, loanApplicationDetailViewExpandArray),
      );
      queryClient.invalidateQueries(loanApplicationQueryKeys.riskAssessments(data?.id));
    },
    onSuccess: () => {
      enqueueSnackbar(adminVocab.toasts.success.updated(adminVocab.loanApplication.riskAssessment.entityName), {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(adminVocab.toasts.errors.update(adminVocab.loanApplication.riskAssessment.entityName), {
        variant: 'error',
      });
    },
  });

  const initialValues = useMemo(
    () => ({
      creditScore: 0,
      comment: '',
      maximumAmount: 0,
    }),
    [],
  );

  const validationSchema = useMemo(() => {
    // const minLoanProduct = loanProduct?.minLoanAmount ? parseInt(loanProduct?.minLoanAmount) : 0;
    // const maxLoanProduct = loanProduct?.maxLoanAmount ? parseInt(loanProduct?.maxLoanAmount) : Number.MAX_SAFE_INTEGER;
    return yup.object({
      creditScore: yup
        .number()
        .typeError(adminVocab.errors.mustBeNumber)
        .required(adminVocab.errors.required(adminVocab.loanApplication.riskAssessment.properties.creditScore))
        .min(0, adminVocab.errors.min(adminVocab.loanApplication.riskAssessment.properties.creditScore, 0))
        .max(100, adminVocab.errors.max(adminVocab.loanApplication.riskAssessment.properties.creditScore, 100)),
      // creditCategory: yup.string().required(adminVocab.errors.required(adminVocab.loanApplication.riskAssessment.properties.riskCategory)),
      comment: yup
        .string()
        .required(adminVocab.errors.required(adminVocab.loanApplication.riskAssessment.properties.comments)),
      maximumAmount: yup
        .number()
        .typeError(adminVocab.errors.mustBeNumber)
        .positive(adminVocab.errors.positiveNumber)
        .required(adminVocab.errors.required(adminVocab.loanApplication.riskAssessment.properties.maxLoanAmount)),
    });
  }, []);

  const handleFormSubmit = () => {
    formRef.current?.submit();
    setConfirmModalShown(false);
  };

  return (
    <Form
      ref={formRef}
      onSubmit={(values) => {
        const riskCategoryId = loanProduct?.riskCategories?.find((riskCategory) => {
          return riskCategory.name === riskCategoryValue;
        })?.id;

        addRiskAssessment({
          loanApplicationId: data?.id,
          riskAssessment: {
            ...{
              creditScore: parseInt(values.creditScore),
              comment: values.comment,
              maximumAmount: values.maximumAmount,
              rejected: !riskCategoryId,
              analystType: riskAnalystType,
            },
            ...(riskCategoryId && { riskCategoryId }),
          },
        });
      }}
      className={classes.container}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <div className={classes.creditScore}>
        <div className={classes.riskAssessmentState}>
          <p>{adminVocab.loanApplication.riskAssessment.assessmentOutcome}</p>
          <Tag
            color={
              preliminaryAssessmentResult === 'NA'
                ? 'neutralGray'
                : getTagColorByLoanApplicationState(preliminaryAssessmentResult)
            }
            className={classes.tagCentered}
          >
            {vocab().loanState[preliminaryAssessmentResult]}
          </Tag>
        </div>
        <div>
          <p>{adminVocab.loanApplication.riskAssessment.properties.creditScore}</p>
          <Input type="number" name="creditScore" fullWidth min={0} max={100} height={48} />
        </div>
        <div>
          <p>{adminVocab.loanApplication.riskAssessment.properties.riskCategory}</p>
          {loanProduct?.riskCategories && (
            <RiskAssessmentDropdown
              levels={loanProduct?.riskCategories?.length}
              onChange={(option: IRiskAssessmentOption) => {
                setRiskCategoryValue(option?.value);
              }}
            />
          )}
        </div>
        <div>
          <p>{adminVocab.loanApplication.riskAssessment.properties.maxLoanAmount}</p>
          <Input type="number" name="maximumAmount" fullWidth height={48} />
        </div>
      </div>
      <div className={classes.comments}>
        <TextArea
          name="comment"
          label={adminVocab.loanApplication.riskAssessment.properties.comments}
          placeholder={adminVocab.placeholders.comments}
          variant="floatingLabel"
        />
      </div>
      <Button
        onClick={() => {
          if (formRef?.current?.isValid && riskCategoryValue) {
            setConfirmModalShown(true);
            return;
          }

          if (!formRef?.current?.isValid) {
            formRef.current?.submit();
            return;
          }

          if (!riskCategoryValue) {
            enqueueSnackbar(
              adminVocab.errors.required(adminVocab.loanApplication.riskAssessment.properties.riskCategory),
              {
                variant: 'error',
              },
            );
            return;
          }
        }}
        type="button"
        className={classes.submit}
        variant="outlined"
      >
        {adminVocab.loanApplication.riskAssessment.submit}
      </Button>
      {confirmModalShown && (
        <ModalDialog
          open={confirmModalShown}
          setOpen={setConfirmModalShown}
          title={adminVocab.loanApplication.riskAssessment.confirmTitle}
          onSubmit={handleFormSubmit}
        >
          {adminVocab.loanApplication.riskAssessment.confirmDescription}
        </ModalDialog>
      )}
    </Form>
  );
};

export default RiskAnalysisForm;
