import { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';
import Tag from '@stryberventures/gaia-react.tag';
import Button from '@stryberventures/gaia-react.button';
import {
  ContractState,
  DealStatus,
  DisbursementState,
  getTagColorByDisbursementState,
  IContract,
  IOffer,
  loanApplicationQueryKeys,
  LoanApplicationState,
  numberWithCommas,
  queryClient,
  useAddLoanMutation,
  useContractsPerLoanApplicationQuery,
  useCreatePaymentAccountMutation,
  useDisburseLoanMutation,
  useLoanApplicationQuery,
} from '@nayla/common';
import adminVocab from '@/vocabulary';
import { enqueueSnackbar } from 'notistack';
import { loanApplicationDetailViewExpandArray } from '@/screens/LoanApplications/detail';
import dayjs from 'dayjs';
import vocab from '@nayla/vocabulary';
import { useQueryClient } from '@tanstack/react-query';

type TActionButton = 'dealNotConfirmed' | 'paymentAccount' | 'bankAccount' | 'disburseLoan' | 'disbursed';

const DisbursementTab: FC = () => {
  const classes = useStyles();
  const loanApp = useAtomValue(loanApplication);
  const { data: contracts } = useContractsPerLoanApplicationQuery(loanApp?.id, ['offer']);
  const [signedOffer, setSignedOffer] = useState<IOffer | null>(null);
  const { data } = useLoanApplicationQuery(loanApp?.id, ['businessSnapshot', 'offers']);

  useEffect(() => {
    const id = contracts
      // sort contracts by createdAt date from newest to oldest
      ?.sort((a: IContract, b: IContract) => dayjs(b?.createdAt).valueOf() - dayjs(a?.createdAt).valueOf())
      .find((contract) => contract.contractStatus === ContractState.SIGNED)?.offerId;
    const offer = data?.offers?.find((o) => o.id === id);
    setSignedOffer(offer ? offer : null);
  }, [contracts, data]);

  const disbursementState: DisbursementState | string =
    data?.state === LoanApplicationState.DISBURSE ? data?.subState : adminVocab.na;

  const getActionButtonType = (): TActionButton => {
    if (!loanApp?.islamicDealTransactionKey || loanApp.islamicDealStatus !== DealStatus.CONFIRMED) {
      return 'dealNotConfirmed';
    }

    if (!loanApp?.rosomAccountId) {
      return 'paymentAccount';
    }

    if (!loanApp.businessSnapshot.bank || !loanApp.businessSnapshot.bic) {
      return 'bankAccount';
    }

    if (loanApp.state !== LoanApplicationState.DISBURSE) {
      return 'disburseLoan';
    }

    return 'disbursed';
  };

  const actionButton = getActionButtonType();

  return (
    <>
      <p>{adminVocab.loanApplication.payment.disburseEligibility}</p>
      <div className={classes.container}>
        <div className={classes.companyInformation}>
          <p style={{ fontWeight: 600, flex: '1 1 100%' }}>{adminVocab.loanApplication.payment.companyInformation}:</p>
          <p>
            {adminVocab.loanApplication.payment.companyName}: {data?.businessSnapshot?.companyName ?? adminVocab.na}
          </p>
          <div className={classes.divider}></div>
          <p>
            {adminVocab.loanApplication.payment.iban}: {data?.businessSnapshot?.iban ?? adminVocab.na}
          </p>
          <div className={classes.divider}></div>
          <p>
            {adminVocab.loanApplication.payment.bankName}: {data?.businessSnapshot?.bank ?? adminVocab.na}
          </p>
          <div className={classes.divider}></div>
        </div>
        <div className={classes.disbursementStatus}>
          <span style={{ fontWeight: 600, flex: '1 1 100%' }}>{adminVocab.loans.properties.loanAmount}: </span>
          {signedOffer?.amount ? numberWithCommas(signedOffer?.amount) : adminVocab.na}
          <p style={{ fontWeight: 600, flex: '1 1 100%' }}>{adminVocab.loanApplication.payment.disbursementState}:</p>
          <Tag
            className={classes.tagCentered}
            color={
              loanApp?.state === LoanApplicationState.DISBURSE
                ? getTagColorByDisbursementState(disbursementState as DisbursementState)
                : 'neutralGray'
            }
          >
            {loanApp?.state === LoanApplicationState.DISBURSE
              ? adminVocab.loanApplication.payment.disbursementStates[disbursementState as DisbursementState]
              : adminVocab.na}
          </Tag>
        </div>

        <div className={classes.action}>
          {actionButton === 'dealNotConfirmed' && <p>{adminVocab.loanApplication.payment.commodityDealNotConfirmed}</p>}
          {actionButton === 'bankAccount' && <p>{adminVocab.loanApplication.payment.bankAccountNotProvided}</p>}
          {actionButton === 'paymentAccount' && <CreatePaymentAccountButton />}
          {actionButton === 'disburseLoan' && <DisburseLoanButton />}
        </div>
      </div>
    </>
  );
};

const DisburseLoanButton = () => {
  const loanApp = useAtomValue(loanApplication);
  const classes = useStyles();
  const { mutate: createLoan } = useAddLoanMutation({
    onSuccess() {
      queryClient.invalidateQueries(
        loanApplicationQueryKeys.loanApplication(loanApp?.id, loanApplicationDetailViewExpandArray),
      );
    },
  });

  return (
    <Button
      onClick={() => loanApp && createLoan({ loanApplicationId: loanApp.id })}
      className={classes.submit}
      variant="outlined"
    >
      {adminVocab.loanApplication.payment.disburseLoan}
    </Button>
  );
};

const CreatePaymentAccountButton = () => {
  const queryClient = useQueryClient();
  const loanApp = useAtomValue(loanApplication);
  const classes = useStyles();
  const { mutate: createAccount } = useCreatePaymentAccountMutation({
    onSuccess() {
      queryClient.invalidateQueries(
        loanApplicationQueryKeys.loanApplication(loanApp?.id, loanApplicationDetailViewExpandArray),
      );
    },
  });

  return (
    <Button
      onClick={() => loanApp && createAccount({ loanApplicationId: loanApp.id })}
      className={classes.submit}
      variant="outlined"
    >
      {adminVocab.loanApplication.payment.createAccount}
    </Button>
  );
};

export default DisbursementTab;
