import { IUser, IUserSnapshot } from './user';
import { AMLStatus, IBusiness, IBusinessSnapshot } from './business';
import { ILoanProduct, IRiskCategory } from './loan-product';
import { IOffer } from './offer';
import { INote } from './note';
import { IDocumentInfo } from './document';
import { YearlyRevenue } from '../enums';

export interface IAMLData {
  createdAt?: string;
  data?: {
    alert_decisions?: any[];
    customer_reference_id?: string;
    decisions_history?: any[];
    fname?: string;
    iden?: string;
    is_batch_query?: boolean;
    lname?: string;
    query_id?: string;
    query_status?: string;
    query_time?: string;
    query_type?: string;
    query_uuid?: string;
    result_time?: string;
    screen_result?: any[];
    source?: string;
    threshold?: number;
  };
  id?: string;
  isAutomated?: boolean;
  status?: AMLStatus;
}

export interface ILoanApplicationStateTransition {
  id: string;
  from: string;
  to: string;
  loan_application: string;
  triggered_by: string;
  transitioned_at: string;
}

export interface IRiskAssessment {
  id: string;
  maximumAmount: string;
  creditScore: number;
  comment: string;
  riskCategory: IRiskCategory;
  loanApplication: ILoanApplication;
  creditRiskAnalyst: IUser;
  rejected: boolean;
  createdAt: string;
  updatedAt: string;
  analystType: RiskAnalystType;
}

export interface INewRiskAssessment extends Partial<IRiskAssessment> {
  riskCategoryId?: string;
  rejected: boolean;
}

export enum RiskAnalystType {
  UNDERWRITER_LEVEL_1 = 'underwriter_level_1',
  UNDERWRITER_LEVEL_2 = 'underwriter_level_2',
}

export enum LoanApplicationState {
  PARTIALLY_COMPLETE = 'PARTIALLY_COMPLETE',
  SUBMITTED = 'SUBMITTED',
  PRE_APPROVED = 'PRE_APPROVED',
  UNDERWRITING = 'UNDERWRITING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION',
  OFFER = 'OFFER',
  SIGNED = 'SIGNED',
  DISBURSE = 'DISBURSE',
  EXPIRED = 'EXPIRED',
  DEFERRED = 'DEFERRED',
}

export enum LoanApplicationSubState {
  PERMANENTLY_REJECTED = 'PERMANENTLY_REJECTED',
  TEMPORARILY_REJECTED = 'TEMPORARILY_REJECTED',
  AWAITING_FIRST_ASSESSMENT = 'AWAITING_FIRST_ASSESSMENT',
  AWAITING_SECOND_ASSESSMENT = 'AWAITING_SECOND_ASSESSMENT',
  AWAITING_USER_RESPONSE = 'AWAITING_USER_RESPONSE',
  AML = 'AML',
  CONTRACT = 'CONTRACT',
  PENDING = 'PENDING',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
}

export enum DealStatus {
  CREATED = 'created',
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
}

export interface IRejectPayload {
  rejectionReason: string;
}

export interface ILoanApplication {
  id: string;
  externalId: string;
  state: LoanApplicationState;
  subState: LoanApplicationSubState;
  eligibilityCheckSuccessful: boolean;
  amount: string;
  terms: number;
  purpose: string;
  documents: IDocumentInfo[];
  rejectionReason: string;
  deferReason: string;
  user: IUser;
  business: IBusiness;
  businessSnapshot: IBusinessSnapshot;
  userSnapshot: IUserSnapshot;
  loanProduct: ILoanProduct;
  offers: IOffer[];
  loanApplicationStateTransitions: ILoanApplicationStateTransition[];
  createdAt: string;
  updatedAt: string;
  notes: INote[];
  amlProfileUuid?: string;
  islamicDealTransactionKey?: string;
  islamicDealStatus?: DealStatus;
  rosomAccountId?: string;
}

export interface ICreateUserSnapshot {
  firstName: string;
  lastName: string;
  profession: string;
  education: string;
}

export interface ICreateBusinessSnapshot {
  id: string;
  companyName: string;
  registrationDate: string;
  locationName: string;
  yearlyRevenue: YearlyRevenue;
  industry: string;
  employeeCount: string;
}

export interface INewLoanApplication {
  amount: string;
  terms: number;
  purpose: string;
  loanProductId: string;
  user: ICreateUserSnapshot;
  business: ICreateBusinessSnapshot;
  documentsIds: string[];
}
