import OffersTable from '@/components/OffersTable';
import {
  AMLStatus,
  DisbursementState,
  LoanApplicationState,
  LoanApplicationSubState,
  useOffersQuery,
} from '@nayla/common';
import { useAtomValue } from 'jotai';
import { loanApplication } from '@/store/loan-application';

const OffersTab = () => {
  const loanApp = useAtomValue(loanApplication);
  const { data: offers } = useOffersQuery(loanApp?.id);

  const disbursementPassed =
    loanApp?.state === LoanApplicationState.DISBURSE &&
    loanApp?.subState === (DisbursementState.SUCCESSFUL as unknown as LoanApplicationSubState);
  return (
    <OffersTable
      offers={offers}
      amlPassed={
        !disbursementPassed &&
        loanApp?.amlProfileUuid &&
        loanApp?.state !== LoanApplicationState.SIGNED &&
        loanApp?.state !== LoanApplicationState.DISBURSE &&
        loanApp?.state !== LoanApplicationState.REJECTED
      }
      loanApplicationId={loanApp?.id}
    />
  );
};

export default OffersTab;
