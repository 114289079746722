import { authState, mfaState } from '../store';
import { useAtom } from 'jotai';

export const useFetchWrapper = () => {
  const [token, setToken] = useAtom(authState);
  const [, setMfaConfirmed] = useAtom(mfaState);

  function handleResponse(response: Response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        /* maybe add 403 back there */
        if ([401].includes(response.status) && token) {
          setToken(null);
          setMfaConfirmed(null);
          // onError();
          // enqueueSnackbar(vocab.toasts.errors.sessionExpired, { variant: 'error' });
        }

        return Promise.reject(data);
      }

      return data;
    });
  }

  function request(method: string) {
    return (url: string, customConfig: RequestInit = {}) => {
      const config = {
        method,
        ...customConfig,
        headers: JSON.parse(
          // JSON used to override by undefined default values
          JSON.stringify({
            'Content-Type': 'application/json',
            ...(token && { Authorization: `Bearer ${token}` }),
            ...customConfig.headers,
          }),
        ),
      };
      return fetch(url, config).then(handleResponse);
    };
  }

  function requestCSV() {
    return (url: string, customConfig: RequestInit = {}) => {
      const config = {
        method: 'GET',
        ...customConfig,
        headers: {
          Accept: 'text/csv',
          ...(token && { Authorization: `Bearer ${token}` }),
          ...customConfig.headers,
        },
      };
      return fetch(url, config).then((response) => {
        return response.text();
      });
    };
  }

  return {
    get: request('GET'),
    post: request('POST'),
    patch: request('PATCH'),
    put: request('PUT'),
    delete: request('DELETE'),
    getCSV: requestCSV(),
  };
};
