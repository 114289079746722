/*
    Error code general ranges:
    - 1000 ~ 1999: System error
    - 2000 ~ 2999: User error
    - 3000 ~ 3999: Business error
    - 4000 ~ 4999: Loan Application error
    - 5000 ~ 5999: Loan error
    - 6000 ~ 6999: Address error
    - 7000 ~ 7999: Document error
    - 8000 ~ 8999: Validation error
*/

// System error 1000 ~ 1999
// API & general errors 1000 ~ 1099
// Services errors 1100 ~ 1199
enum SystemErrorCode {
  // API & general errors 1000 ~ 1099
  INTERNAL_SERVER_ERROR = 1000,
  UNAUTHORIZED = 1001,
  BAD_REQUEST = 1002,
  FORBIDDEN = 1003,
  NOT_FOUND = 1004,

  // Services errors 1100 ~ 1199
}

// User error 2000 ~ 2999
// user errors 2000 ~ 2099
// KYC error 2100 ~ 2199
enum UserErrorCode {
  // user errors 2000 ~ 2099
  USER_NOT_FOUND = 2000,
  USER_ALREADY_EXISTS = 2001,
  INVALID_CREDENTIALS = 2002,
  PHONE_IS_NOT_OWNED_BY_USER = 2003,

  // KYC error 2100 ~ 2199
  KYC_PENDING = 2100,
  KYC_DUPLICATE_REQUEST = 2101,
  KYC_EXPIRED = 2102,
  KYC_REJECTED = 2103,
  KYC_NOT_RUN_YET = 2104,
  KYC_FAILED = 2105,
  KYC_ALREADY_COMPLETED = 2106,
  KYC_NOT_COMPLETED = 2107,

  TAHAQOQ_NOT_MATCHED = 2198,
  TAHAQOQ_FAILED = 2199,
}

// Business error 3000 ~ 3999
// Business error 3000 ~ 3099
// KYB error 3100 ~ 3199
// Business snapshot error 3200 ~ 3299
enum BusinessErrorCode {
  // Business error 3000 ~ 3099
  BUSINESS_NOT_FOUND = 3000,
  BUSINESS_DOES_NOT_HAVE_AN_ADDRESS = 3002,
  BUSINESS_NOT_VERIFIED = 3003,
  BUSINESS_USER_NOT_BUSINESS_OWNER = 3004,
  BUSINESS_TYPE_NOT_ALLOWED = 3005,

  // KYB error 3100 ~ 3199
  KYB_UNVERIFIED = 3100,
  KYB_REJECTED = 3101,
  KYB_FAILED = 3102,
  KYB_ALREADY_VERIFIED = 3103,
  // Business snapshot error 3200 ~ 3299
  BUSINESS_SNAPSHOT_NOT_FOUND = 3200,
}

// Loan Application errors 4000 ~ 4999
// Loan Application errors 4000 ~ 4099
// Risk assessment errors 4100 ~ 4199
// Offer errors 4200 ~ 4299
// Commodity deal errors 4300 ~ 4399
// Contract errors 4400 ~ 4499
// AML errors 4500 ~ 4599
enum LoanApplicationErrorCode {
  // Loan Application errors 4000 ~ 4099
  LOAN_APPLICATION_NOT_FOUND = 4000,
  LOAN_APPLICATION_GENERAL_ERROR = 4001,
  LOAN_APPLICATION_DOES_NOT_HAVE_OFFER = 4002,
  LOAN_APPLICATION_DOES_NOT_HAVE_ACCEPTED_OFFER = 4003,
  LOAN_APPLICATION_DOES_NOT_HAVE_RISK_ASSESSMENT = 4004,
  LOAN_APPLICATION_ALREADY_DISBURSED = 4005,
  INVALID_LOAN_PURPOSE = 4006,

  // Risk assessment errors 4100 ~ 4199
  RISK_ASSESSMENT_NOT_FOUND = 4100,

  // Offer errors 4200 ~ 4299
  OFFER_NOT_FOUND = 4200,
  OFFER_FAILED_TO_ACCEPT = 4201,

  // Commodity deal errors 4300 ~ 4399
  DEAL_NOT_FOUND = 4300,
  DEAL_CREATION_FAILED = 4301,
  DEAL_ALREADY_CONFIRMED = 4302,
  DEAL_CONFIRMATION_FAILED = 4303,

  // Contract errors 4400 ~ 4499
  CONTRACT_NOT_FOUND = 4400,
  CONTRACT_DOWNLOAD_FAILED = 4401,
  CONTRACT_GENERAL_ERROR = 4402,

  // AML errors 4500 ~ 4599
  AML_PROFILE_NOT_FOUND = 4500,
  AML_PROFILE_NOT_APPROVED = 4501,
}

// Loan error 5000 ~ 5999
// Loan errors 5000 ~ 5099
// payment errors 5100 ~ 5199
// instalment errors 5200 ~ 5299
enum LoanErrorCode {
  // Loan Product errors 5000 ~ 5099
  LOAN_PRODUCT_NOT_FOUND = 5000,

  // loan errors 5100 ~ 5199
  LOAN_NOT_FOUND = 5100,

  // payment errors 5200 ~ 5299
  PAYMENT_NOT_FOUND = 5200,

  // installment errors 5300 ~ 5399
  INSTALLMENT_NOT_FOUND = 5300,
}

// Address error 6000 ~ 6999
enum AddressErrorCode {
  ADDRESS_NOT_FOUND = 6000,

  // Location error 6100 ~ 6199
  LOCATION_NOT_FOUND = 6100,
}

// Document error 7000 ~ 7999
enum DocumentErrorCode {
  DOCUMENT_NOT_FOUND = 7000,
  DOCUMENT_DELETION_FAILED = 7002,
  DOCUMENT_UPLOAD_FAILED = 7003,
  DOCUMENT_REQUIRED = 7004,
  DOCUMENT_MAX_COUNT_EXCEEDED = 7005,
  DOCUMENT_INVALID_TYPE = 7006,
  DOCUMENT_MAX_SIZE_EXCEEDED = 7007,
  DOCUMENT_ALREADY_EXISTS = 7008,
}

// Validation error 8000 ~ 8999
enum ValidationErrorCode {
  // General validation error 8000 ~ 8099
  GENERAL_VALIDATION_ERROR = 8000,
  MISSING_REQUIRED_FIELD = 8001,

  // user/business info errors 8100 ~ 8149
  INVALID_PHONE_NUMBER = 8100,
  INVALID_CRN = 8101,
  INVALID_NID = 8102,
  INVALID_BANK_NAME = 8103,
  INVALID_BANK_BIC = 8104,
  INVALID_BANK_IBAN = 8105,
  INVALID_PROFESSION = 8106,
  INVALID_EDUCATION = 8107,
  INVALID_INDUSTRY = 8108,
  INVALID_YEARLY_REVENUE = 8109,
  INVALID_EMPLOYEE_COUNT = 8110,
  INVALID_LOCATION = 8111,
  INVALID_PASSWORD = 8112,

  // amount/terms errors 8150 ~ 8199
  INVALID_AMOUNT = 8150,
  AMOUNT_LESS_THAN_MINIMUM = 8151,
  AMOUNT_GREATER_THAN_MAXIMUM = 8152,
  AMOUNT_GREATER_THAN_MAX_AMOUNT_FROM_RISK_ASSESSMENT = 8153,

  INVALID_TERMS = 8154,
  TERMS_LESS_THAN_MINIMUM = 8155,
  TERMS_GREATER_THAN_MAXIMUM = 8156,
}

export const ErrorCode = {
  ...SystemErrorCode,
  ...UserErrorCode,
  ...BusinessErrorCode,
  ...LoanApplicationErrorCode,
  ...LoanErrorCode,
  ...AddressErrorCode,
  ...ValidationErrorCode,
  ...DocumentErrorCode,
};

export type TErrorCode =
  | SystemErrorCode
  | UserErrorCode
  | BusinessErrorCode
  | LoanApplicationErrorCode
  | LoanErrorCode
  | AddressErrorCode
  | ValidationErrorCode
  | DocumentErrorCode;
