import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import { getCheckUserKYCStatus, getMeUser, updateMeUser } from '../requests';
import { IMutationOptions } from '../../interfaces/mutation';
import { IKYCResponseData, IMe, IMeCheckPhoneOwnership, IMeKYC } from '../../interfaces';
import { common } from '..';

export const useMeUserQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(['me'], ({ signal }) => getMeUser({ fetcher, options: { signal } }), {
    onError: () => {
      // enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.users.tableTitle), { variant: 'error' });
    },
  });
};

export const useUpdateMeUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(({ user }: { user: IMe }) => updateMeUser(user, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useKycMeMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    async (data: IMeKYC): Promise<IKYCResponseData> => {
      return fetcher.post(`${common.endpoints.path.ME_ENDPOINT}/kyc`, { body: JSON.stringify(data) });
    },
    {
      onSuccess,
      onError,
      onSettled,
    },
  );
};

export const useMeOnKycUserChecQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(['me'], ({ signal }) => getCheckUserKYCStatus({ fetcher, options: { signal } }), {
    onError: () => {
      // enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.users.tableTitle), { variant: 'error' });
    },
  });
};

export const useCheckPhoneOwnershipMutation = (options?: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IMeCheckPhoneOwnership) => {
    return fetcher.post(`${common.endpoints.path.ME_ENDPOINT}/check-phone-owner`, { body: JSON.stringify(data) });
  }, options);
};
